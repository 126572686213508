import React, { memo, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { compose, mapProps } from '@shakacode/recompose';

import { CreateMenuItemReviewDocument } from '~/libs/gql/mutations/menus/createMenuItemReviewMutation.generated';
import { MenuItemsSearchDocument, type MenuItemsSearchQuery } from '~/libs/gql/queries/menus/menuItemsSearchQuery.generated';
import { useMenuItemLazyQuery } from '~/libs/gql/queries/menus/menuItemQuery.generated';
import { useRestaurantLocationsWithoutPaginationQuery } from '~/libs/gql/queries/locations/restaurantLocationsWithoutPaginationQuery.generated';
import { sortByKey } from '../../../utils/arrays';
import { useSnackbar } from '../../../utils/withSnackbar';
import { notBlankValidator } from '../../../utils/forms';
import { withCurrentSession } from '../../../shared/CurrentSessionProvider';

import BasicForm, {
  SelectAsyncGroup,
  SelectGroup,
  SubmitGroup,
  TextAreaGroup,
  TextFieldGroup,
  type BasicFormApi,
} from '../../../admin/shared/forms/BasicForm';
import Mutation from '../../../shared/Mutation';
// @ts-expect-error Internal file is not TypeScript
import FollowDisclaimer from '../../../shared/sessions/FollowDisclaimer';

import type {
  InnerRestaurantReviewFromProps,
  RavesFormHeaderProps,
  RestaurantReviewFormProps,
  VariableType,
} from './interfaces';
import {
  FORM_NAME,
  setDefaultSelectInputAttributes,
  setFormElementMutationObserver,
  updateAttributesOnMenuToggle,
} from './utils';

const RavesFormHeader = ({ backgroundSize, imageUrl }: RavesFormHeaderProps) => {
  if (!imageUrl) {
    return null;
  }
  return (
    <div className="pm-raves-form-header">
      <div
        className="pm-raves-form-img"
        style={{
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize,
        }}
      />
    </div>
  );
};

const useMessages = () => {
  const intl = useIntl();

  return {
    contentInputTitle: intl.formatMessage({
      defaultMessage: 'Add your review',
      id: 'restaurant_review_form.content_input.title',
    }),
    emailInputTitle: intl.formatMessage({
      defaultMessage: 'Add your email',
      id: 'restaurant_review_form.email_input.title',
    }),
    locationInputPlaceholder: intl.formatMessage({
      defaultMessage: 'Select a location',
      id: 'restaurant_review_form.location_input.placeholder',
    }),
    locationInputTitle: intl.formatMessage({
      defaultMessage: 'Which location did you visit?',
      id: 'restaurant_review_form.location_input.title',
    }),
    menuItemInputSearch: intl.formatMessage({
      defaultMessage: 'Type to search our menu...',
      id: 'restaurant_review_form.menu_item_input.search',
    }),
    menuItemInputTitle: intl.formatMessage({
      defaultMessage: 'Which item did you try?',
      id: 'restaurant_review_form.menu_item_input.title',
    }),
    submitButtonSubmitReview: intl.formatMessage({
      defaultMessage: 'Submit Review',
      id: 'restaurant_review_form.submit_button.submit_review',
    }),
    submitButtonThanks: intl.formatMessage({
      defaultMessage: 'Thanks!',
      id: 'restaurant_review_form.submit_button.thanks',
    }),
  };
};

const RestaurantReviewForm = ({
  restaurant,
  selectedLocationId = null,
  selectedMenuItemId = null,
  userEmail,
}: RestaurantReviewFormProps) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isLocationSelectOpen, setIsLocationSelectOpen] = useState<boolean>(false);
  const [isMenuItemSelectOpen, setIsMenuItemSelectOpen] = useState<boolean>(false);
  const { data: locationData } = useRestaurantLocationsWithoutPaginationQuery({
    variables: {
      restaurantId: restaurant.id,
    },
  });
  const [getMenuItem, { data, loading: menuItemLoading }] = useMenuItemLazyQuery();
  const { showSnackbarError } = useSnackbar();
  const messages = useMessages();

  const locationOptions = useMemo(() => locationData?.restaurant?.locations ?? [], [locationData]);
  const menuItemValue = !menuItemLoading && data && data.menuItem && { label: `${data.menuItem.name} (${data.menuItem?.section?.menu?.name})`, value: data.menuItem.id };

  useEffect(() => {
    setDefaultSelectInputAttributes();
    const formMutationObserver = setFormElementMutationObserver();

    return () => formMutationObserver.disconnect();
  }, []);

  useEffect(() => {
    updateAttributesOnMenuToggle(messages.locationInputTitle, isLocationSelectOpen);
  }, [messages.locationInputTitle, isLocationSelectOpen]);

  useEffect(() => {
    updateAttributesOnMenuToggle(messages.menuItemInputTitle, isMenuItemSelectOpen);
  }, [messages.menuItemInputTitle, isMenuItemSelectOpen]);

  // Render selected menu item featured photo or restaurant logo
  function renderFormImage(menuItemId:number|null) {
    if (menuItemId) {
      if (!menuItemLoading && data && data.menuItem && data.menuItem.photos[0] && data.menuItem.photos[0].thumbnailUrl) {
        const imageUrl = data.menuItem.photos[0].thumbnailUrl;
        return (
          <RavesFormHeader backgroundSize="cover" imageUrl={imageUrl} />
        );
      }
    }

    return <RavesFormHeader backgroundSize="contain" imageUrl={restaurant.logoUrl} />;
  }

  // Loads menu items from selected location
  function renderMenuItemSelect(locationId:number|null, setValue: BasicFormApi<VariableType>['setValue']) {
    if (!locationId) {
      return (
        <SelectGroup
          key="placeholder"
          aria-label={messages.menuItemInputTitle}
          disabled
          field="menuItemId"
          isRequiredTitleLabel
          options={[]}
          title={messages.menuItemInputTitle}
        />
      );
    }
    return (
      <SelectAsyncGroup
        data-cy="menu_item_select"
        field="menuItemId"
        noOptionsMessage={messages.menuItemInputSearch}
        query={MenuItemsSearchDocument}
        placeholder={messages.menuItemInputSearch}
        title={messages.menuItemInputTitle}
        isRequiredTitleLabel
        toOptions={(asyncData: MenuItemsSearchQuery) => sortByKey(asyncData.menuItems?.records.map(r => ({
          label: `${r.name} (${r.menuName})`,
          value: r.id,
        })), 'label')}
        validate={notBlankValidator}
        variables={{
          isPoppable: true,
          locationId,
          restaurantId: restaurant.id,
        }}
        onMenuClose={() => setIsMenuItemSelectOpen(false)}
        onMenuOpen={() => setIsMenuItemSelectOpen(true)}
        onChange={(menuItem: {label: string, value: number}) => {
          setValue('menuItemId', menuItem.value);
          void getMenuItem({ variables: { id: menuItem.value } });
        }}
        value={menuItemValue}
      />
    );
  }

  // Only required if user is not signed in
  function renderEmailField() {
    if (userEmail) {
      return null;
    }
    return (
      <TextFieldGroup
        isRequiredTitleLabel
        field="email"
        title={messages.emailInputTitle}
        type="email"
        validate={notBlankValidator}
      />
    );
  }

  const locations = sortByKey(locationOptions, 'name').filter(location => location.isLocationEnabled);
  const defaultLocationId = locations.length === 1 ? locations[0]?.id : null;

  return (
    <Mutation
      mutation={CreateMenuItemReviewDocument}
      onCompleted={() => {
        setSubmitted(true);
      }}
      onError={err => showSnackbarError(err)}
    >
      {(createMenuItemReview, { loading }) => (
        <div className="pm-raves-form">
          <BasicForm
            aria-label={FORM_NAME}
            defaultValues={{
              content: null,
              email: userEmail,
              locationId: selectedLocationId || defaultLocationId || null,
              menuItemId: selectedMenuItemId,
            }}
            onSubmit={({ content, email, menuItemId }) => {
              if (content && email && menuItemId) {
                void createMenuItemReview({
                  variables: {
                    content,
                    email,
                    menuItemId,
                  },
                });
              }
            }}
          >
            {({ setValue, values }) => (
              <React.Fragment>
                {renderFormImage(values.menuItemId)}
                <SelectGroup
                  field="locationId"
                  isSearchable={false}
                  isRequiredTitleLabel
                  onMenuClose={() => setIsLocationSelectOpen(false)}
                  onMenuOpen={() => setIsLocationSelectOpen(true)}
                  options={sortByKey(locations.map(location => ({
                    label: location.name,
                    value: location.id,
                  })), 'label')}
                  placeholder={messages.locationInputPlaceholder}
                  title={messages.locationInputTitle}
                  validate={notBlankValidator}
                />
                {renderMenuItemSelect(values.locationId, setValue)}
                <TextAreaGroup
                  isRequiredTitleLabel
                  field="content"
                  title={messages.contentInputTitle}
                  validate={notBlankValidator}
                />
                {renderEmailField()}
                <SubmitGroup
                  block
                  className="pm-review-form-submit"
                  disabled={submitted}
                  loading={loading}
                  size="large"
                  title={submitted ? (
                    messages.submitButtonThanks
                  ) : (
                    messages.submitButtonSubmitReview
                  )}
                />
                <FollowDisclaimer
                  customDisclaimer=" "
                  showGiveawayTerms
                />
              </React.Fragment>
            )}
          </BasicForm>
        </div>
      )}
    </Mutation>
  );
};

export default compose<InnerRestaurantReviewFromProps, RestaurantReviewFormProps>(
  withCurrentSession,
  mapProps<RestaurantReviewFormProps, InnerRestaurantReviewFromProps>(({ currentSession, ...props }) => ({
    ...props,
    userEmail: currentSession.user?.email,
  })),
)(memo(RestaurantReviewForm));
